<template>
	<v-container class="mt-4 ml-2">
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title>
	  		    Horarios de apertura
	  		    <v-spacer></v-spacer>
	  		    <v-btn color="secondary" rounded small @click="getHorariosEmpleados(), initialize()" class="mr-2">Actualizar</v-btn>
	  		    <v-btn color="primary"   rounded small @click="dialogRelacionar = true">Agregar</v-btn>
	  		  </v-card-title>
	  		  <v-card-text class="py-0">
	  		    <div>
		  		    <!-- Disponibilidad -->
		  		    <v-row>
		  		    	<v-col cols="12" class="pb-0 pt-2">
		  		    		<v-card class="elevation-0 transparent">
		  		    		  <v-card-text class="mt-6">
		  		    		  	<v-row>
		  		    		  		<v-col cols="12" md="6" lg="4">
		  		    		  			<v-text-field
		  		    		  			  v-model="search"
		  		    		  			  label="Buscar"
		  		    		  			  rounded
		  		    		  			  filled
		  		    		  			  hide-details
		  		    		  			  dense
		  		    		  			></v-text-field>
		  		    		  		</v-col>
		  		    		  	</v-row>
		  		    		    <v-row>
		  		    		    	<v-col cols="12" sm="6" md="5" lg="4" xl="3" v-for="(horario, i) in filterUsuarios" :key="i">
		  		    		    		<v-card class="shadowCard">
			  		    		    		<v-card-title class="pb-0">
			  		    		    			<span class="text-subtitle-1">
			  		    		    		  	{{ horario.nombre_completo }}
			  		    		    			</span>
			  		    		    		  <v-spacer></v-spacer>
			  		    		    		</v-card-title>

			  		    		    		<v-card-title class="pt-0">
			  		    		    			<span class="text-subtitle-1">
			  		    		    		  	<v-select
			  		    		    		  	  :items="planteles"
			  		    		    		  	  v-model="horario.id_plantel_oficial"
			  		    		    		  	  label="Plantel"
			  		    		    		  	  item-text="plantel"
			  		    		    		  	  item-value="idplantel"
			  		    		    		  	  dense
			  		    		    		  	  filled
			  		    		    		  	  hide-details
			  		    		    		  	  @change="updateSucursal( horario )"
			  		    		    		  	></v-select>
			  		    		    			</span>
			  		    		    		  <v-spacer></v-spacer>
			  		    		    		</v-card-title>

			  		    		    		<v-card-title class="pt-0">
			  		    		    			<span class="text-subtitle-1">
			  		    		    		  	<v-switch 
			  		    		    		  		label="Activo" 
			  		    		    		  		v-model="horario.activo"
			  		    		    		  		class="mt-0"
			  		    		    		  		hide-details
			  		    		    		  		@click="updateEstatus( horario )"
			  		    		    		  	></v-switch>
			  		    		    			</span>
			  		    		    		  <v-spacer></v-spacer>
			  		    		    		</v-card-title>

			  		    		    		<v-card-text>
															<v-data-table
															  dense
														    :headers="headers"
														    :items="horario.horarios"
														    item-key="name"
														    class="elevation-0"
														    hide-default-footer
														    :items-per-page="100"
														    :mobile-breakpoint="200"
															>
																<template v-slot:item.actions="{ item }">
														      <v-icon
														        small
														        @click="deleteItem(item)"
														        color="red"
														      >
														        mdi-delete
														      </v-icon>
														    </template>
														   </v-data-table>	    		    		  
			  		    		    		</v-card-text>
			  		    		    		<v-card-actions>
			  		    		    		  <v-btn 
			  		    		    		  	color="primary" 
			  		    		    		  	rounded 
			  		    		    		  	class="text-capitalize" 
			  		    		    		  	small
			  		    		    		  	@click="dialogHoraAdd( horario )"
			  		    		    		  >
			  		    		    				Agregar Horarios
			  		    		    			</v-btn>
			  		    		    			<v-spacer></v-spacer>
			  		    		    			<v-btn 
			  		    		    		  	color="red"
			  		    		    		  	dark
			  		    		    		  	rounded 
			  		    		    		  	class="text-capitalize"
			  		    		    		  	small 
			  		    		    		  	@click="dialogEliminarUsuario( horario )"
			  		    		    		  >
			  		    		    				Eliminar
			  		    		    			</v-btn>
			  		    		    		</v-card-actions>
		  		    		    		</v-card>
		  		    		    	</v-col>
		  		    		    </v-row>
		  		    		  </v-card-text>
		  		    		</v-card>
		  		    	</v-col>
		  		    </v-row>
	  		    </div>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialog de agregar horario -->
	  <v-dialog
      v-model="dialogHora"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title class="text-subtitle-1" >
          Horarios
        </v-card-title>
        <v-card-text>
        	<v-row v-for="(dia, i) in dias" :key="i">
        		<v-col cols="12" class="d-flex align-center">
		          <v-avatar
		            size="44"
		            color="primary"
		            rounded
		            class="elevation-1 mr-4 white--text"
		          >
		            {{ dia.dia }}
		          </v-avatar>
		          <v-text-field
		            filled
		            v-model=" dia.hora"
		            hide-details
		            dense
		            type="time"
		          ></v-text-field>
					  </v-col>
        	</v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red" dark small rounded
            @click="dialogHora = false"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary" dark small rounded
            @click="guardarHorario()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para relacionar -->
    <v-dialog
      v-model="dialogRelacionar"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title class="text-subtitle-1" >
          Relacionar usuarios
        </v-card-title>
        <v-card-text>
        	<v-row>
        		<v-col cols="12">
        			<v-autocomplete
  		    			outlined
  		    			dense
  		    			clearable
				        v-model="usuario"
				        :items="usuarios"
				        label="Selecciona un usuario"
				        persistent-hint
				        prepend-inner-icon="mdi-account"
				        hide-details
				        item-text="nombre_completo"
				        item-value="id_usuario"
				      >
				      </v-autocomplete>
        		</v-col>
        		<v-col cols="12">
        			<v-autocomplete
  		    			outlined
  		    			dense
  		    			clearable
				        v-model="trabajador"
				        :items="trabajadores"
				        label="Selecciona un trabajador"
				        persistent-hint
				        prepend-inner-icon="mdi-account"
				        hide-details
				        item-text="nombre_completo"
				        item-value="id"
				      >
				      </v-autocomplete>
        		</v-col>
        	</v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red" dark small rounded
            @click="dialogRelacionar = false"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          	v-if="usuario && trabajador"
            color="primary" dark small rounded
            @click="guardarRelacion()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Eliminar la hora -->
    <v-dialog v-model="dialogDeleteHora" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Seguro que deseas eliminar esta hora?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteHora = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="deleteHora()">SI</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEliminar" max-width="500px">
      <v-card>
        <v-card-title class="text-h6 align-center">
        	¿Deseas eliminar el horario de este usuario?
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" small rounded  @click="dialogEliminar = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" small rounded dark @click="deleteUsuario()">SI</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

		<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'


	var moment = require('moment');
  moment.locale(); 

  export default {
		
		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search:'',
      usuario:null, 
      usuarios:[],
      dialogRelacionar: false,
      dialogHora: false,
      dialogEliminar:false,

      trabajador:null,
      trabajadores:[],
			horariosApertura:[],

			planteles:[],

      headers: [
        { text: 'Día'       , value: 'diaNombre' },
        { text: 'Inicio'    , value: 'hora_inicio' },
        { text: 'Acciones'  , value: 'actions' },
      ],

      dias:[
      	{dia:'Lu'  , valor: 1, hora: '08:00'},
      	{dia:'Ma'  , valor: 2, hora: '08:00'},
      	{dia:'Mi'  , valor: 3, hora: '08:00'},
      	{dia:'Ju'  , valor: 4, hora: '08:00'},
      	{dia:'Vi'  , valor: 5, hora: '08:00'},
      	{dia:'Sá'  , valor: 6, hora: '08:00'},
      	{dia:'Do'  , valor: 7, hora: '08:00'}
      ],

			dialogHoraEditar: false,
			dia:'',
			dialogDeleteHora:false,
			idhorarios_apertura:null
    }),

    async created ( ) {
    	await this.initialize()
    	await this.getHorariosEmpleados( )
    },

    computed: {
    	filterUsuarios( ){
    		if( this.search ){
    			return this.horariosApertura.filter( el => {
    				let nombre_completo = el.nombre_completo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")
    				const buscar        = this.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")
    				// return nombre_completo.match(buscar) && el.unidad_negocio == 2;
    				return nombre_completo.match(buscar);
    			})
    		}else{
    			return this.horariosApertura
    		}
    	}
    },

    methods:{

    	getHorariosEmpleados( ){
    		this.horariosApertura = [] 
    		this.cargar = true
    		this.$http.get('obtener.horarios.apertura').then(response=>{
    			this.horariosApertura = response.data
    			this.cargar = false
    		}).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
    	},

    	initialize () {
    		this.usuarios = []
    		this.trabajadores = []
    		this.cargar = true
    		this.$http.get('obtener.trabajadores.usuarios').then(response=>{
    			this.usuarios     = response.data.usuariosActivos
    			this.trabajadores = response.data.trabajadores
    			this.planteles    = response.data.planteles
    		}).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
    	},

			guardarRelacion () {
				this.cargar = true
				const payload = {
					id_usuario: this.usuario,
					id_trabajador: this.trabajador
				}
				this.$http.post('relacion.usuarios', payload ).then(response=>{
					this.usuario          = null
					this.trabajador       = null
					this.dialogRelacionar = false
					this.validarSuccess( response.data.message )
					this.getHorariosEmpleados()
				}).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
			},

			deleteItem (value) {
				this.idhorarios_apertura = value.idhorarios_apertura
				this.dialogDeleteHora = true
			},

			deleteHora () {
				this.$http.delete('horario.apertura.delete/' + this.idhorarios_apertura).then(response=>{
					this.dialogDeleteHora = false
	        this.validarSuccess( response.data.message )
					this.getHorariosEmpleados()
				}).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
			},

			dialogHoraAdd( usuario ){
				this.usuario = usuario
				this.dialogHora = true
			},
			
			dialogEliminarUsuario( usuario ){
				this.usuario = usuario
				this.dialogEliminar = true
			},

			deleteUsuario () {
				this.$http.delete('apertura.usuario/' + this.usuario.id_usuario ).then(response=>{
					this.dialogEliminar = false
	        this.validarSuccess( response.data.message )
					this.getHorariosEmpleados()
				}).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
			},

			guardarHorario () {
				this.cargar = true
				const payload = {
					id_usuario: this.usuario.id_usuario,
					dias:       this.dias
				}

				this.$http.post('agregar.horarios', payload).then(response=>{
					this.usuario          = null
					this.trabajador       = null
					this.dialogHora       = false
					this.validarSuccess( response.data.message )
					this.getHorariosEmpleados()
	      }).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
			},

			updateSucursal( value ){
				console.log( value) 
				this.cargar = true

				this.$http.post('actualizar.sucursal.apertura', value).then(response=>{
					this.usuario          = null
					this.trabajador       = null
					this.dialogHora       = false
					this.validarSuccess( response.data.message )
					this.getHorariosEmpleados()
	      }).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
			},
			
			updateEstatus ( value ) {
				this.cargar = true
				const payload = {
					id_usuario: value.id_usuario,
					estatus:    value.activo
				}

				this.$http.put('apertura.usuario.update/', payload).then(response=>{
					this.validarSuccess( response.data.message )
					this.getHorariosEmpleados()
	      }).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
			},

    }
  }
</script>